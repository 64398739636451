.drops_tabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 16px;
  .drops_tabs__item {
    width: fit-content;
    min-width: fit-content;
    padding: 6px 16px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 100px;
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    margin-right: 10px;
    margin-bottom: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    span {
      margin-right: 6px;
    }
    &.active {
      background-color: rgba(0, 0, 0, 0.4);
      color: #fff;
      box-shadow: 0 4px 8px rgba(0, 0, 0, .4);
    }
  }
}

.drops_title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 12px;
}

.drops_actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  .drops_acts__search {
    width: 100%;
    div {
      &:nth-child(1) {
        font-size: 11px;
        margin-bottom: 4px;
        opacity: 0.7;
      }
      &:nth-child(2) {
        width: 100%;
        input {
          background-color: transparent;
          width: 100%;
          border: 1px solid rgba(255, 255, 255, 0.2);
          border-radius: 10px;
          padding: 6px 12px;
          outline: none;
        }
      }
    }
  }
  .drops_acts__btns {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    height: 50.28px;
    justify-content: flex-end;
    .flexbtns {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      div {
        width: fit-content;
        min-width: fit-content;
        padding: 6px 16px;
        font-size: 14px;
        font-weight: 600;
        border-radius: 100px;
        background-color: rgba(0, 0, 0, 0.1);
        transition: all 0.3s ease;
        margin-right: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: rgba(255, 255, 255, 0.6);
        cursor: pointer;
        &:last-child {
          margin-right: 0px !important;
        }
        span {
          margin-right: 6px;
        }
        &.active {
          background-color: rgba(0, 0, 0, 0.4);
          color: #fff;
          box-shadow: 0 4px 8px rgba(0, 0, 0, .4);
        }
      }
    }
  }
}