.SelectedUsers__title {
  font-size: 12px;
  font-weight: 700;
  opacity: 0.3;
}

.SelectedUsers {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 4px;
  margin-bottom: 12px;
  .SelectedUsers__item {
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    padding: 4px 10px 4px 4px;
    border-radius: 100px;
    cursor: pointer;
    .SelectedUsers__item_avatar {
      margin-right: 6px;
      div {
        width: 24px;
        height: 24px;
        border-radius: 100px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .SelectedUsers__item_name {
      font-size: 12px;
      font-weight: 600;
    }
  }
}