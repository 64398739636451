.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  animation-name: appear;
  animation-duration: 300ms;
}

.modal_dialog {
  width: 100%;
  max-width: 550px;
  background: #151515;
  border-radius: 12px;
  position: relative;
  margin: 0 20px;
  max-height: calc(100vh - 40px);
  text-align: left;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: slide-in;
  -webkit-animation-duration: 0.3s;
  animation-name: slide-in;
  animation-duration: 0.3s;
}

.modal_header,
.modal_footer {
  display: flex;
  align-items: center;
  padding: 12px 16px 0px;
}

.modal_header {
  justify-content: space-between;
  .modal_header__title {
    margin: 0;
    color: #fff;
  }
  .modal_header__close {
    cursor: pointer;
    padding: 0px 8px;
    font-size: 20px;
    color: #fff;
  }
}

.modal_body {
  overflow: auto;
  .modal_body__content {
    padding: 16px;
  }
}

@media screen and (min-width: 1024px) {
  .modal_body {
    padding: 0 24px;
  }
  .modal_header__title {
    width: 100%;
    padding-left: 24px;
  }
}

.modal_footer {
  border-top: 1px solid #2a2a2a;
  justify-content: flex-end;
}

@keyframes appear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slide-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}