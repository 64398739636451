.mints_title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  background-color: #0000001f;
  width: fit-content;
  padding: 6px 18px;
  border-radius: 6px;
  cursor: pointer;
}

.mints_empty {
  font-size: 22px;
  opacity: 0.4;
  padding: 74px 0;
  text-align: center;
}

.mints_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  .mints_list__item {
    position: relative;
    background-color: rgba(0, 0, 0, 0.245);
    color: #fff;
    padding: 8px 12px;
    border-radius: 12px;
    width: 100%;
    cursor: pointer;
    margin-bottom: 8px;
    &.mints_list__item__selected {
      background-color: rgba(0, 0, 0, 0.69);
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    }
    .mints_list__item_status {
      position: absolute;
      right: 36px;
      width: 8px;
      height: 8px;
      border-radius: 100px;
      top: 26px;
      opacity: 0.5;
    }
    .mints_list__item_go {
      position: absolute;
      right: 12px;
      top: 16px;
      font-size: 18px;
      opacity: 0.4;
    }
    .mints_list__item_title {
      font-size: 16px;
      font-weight: 700;
    }
    .mints_list__item_sub {
      display: flex;
      flex-direction: row;
      align-items: center;
      .mints_list__item_price {
        font-size: 12px;
        font-weight: 600;
        margin-right: 8px;
      }
      .mints_list__item_type {
        background-color: #04ba04;
        padding: 1px 6px;
        width: fit-content;
        border-radius: 4px;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 600;
      }
      .mints_list__item_type_red {
        background-color: #d50303;
        padding: 1px 6px;
        width: fit-content;
        border-radius: 4px;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 600;
      }
    }
  }
}

.mint_settings {}

.mint_save {
  background: #0c830b;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  border: none;
  outline: none;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

.mint_delete {
  background: #00000030;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  border: none;
  outline: none;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

.mint_save:hover,
.mint_delete:hover {
  background: #fff !important;
  color: #000;
}

.form_fields {
  .form_fields_item {
    margin-bottom: 16px;
    input,
    textarea {
      display: block;
      width: 100%;
      height: 50px;
      padding: 12px 16px;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      color: #fff;
      background-color: #131518;
      background-clip: padding-box;
      border: 1px solid #212529;
      outline: none;
      border-radius: 12px;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
    textarea {
      height: unset !important;
    }
  }
}