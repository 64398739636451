.votings_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  .votings_list__empty {
    letter-spacing: 0.5px;
    font-size: 14px;
    font-weight: 400;
  }
  .votings_list__item {
    position: relative;
    background-color: rgba(0, 0, 0, 0.261);
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 16px;
    width: 100%;
    cursor: pointer;
    max-height: 267px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    .votings_list__item_go {
      position: absolute;
      top: 12px;
      right: 16px;
      font-size: 18px;
      opacity: 0.6;
    }
    .votings_list__item_status {
      position: absolute;
      top: 20px;
      right: 42px;
      span {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 10px;
        background-color: rgb(210, 5, 5);
        &.votings_list__item_status_active {
          background-color: rgb(9, 188, 9) !important;
        }
      }
    }
    .votings_list__item_data {
      .votings_list__item_title {
        font-size: 18px;
        font-weight: 600;
      }
      .votings_list__item_desc {
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
}

.form_fields {
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-bottom: 24px;
  .form_title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  .form_fields_item {
    margin-bottom: 10px;
    .form_fields_item__label {
      font-size: 14px;
      margin-bottom: 4px;
    }
    input,
    textarea {
      display: block;
      width: 100%;
      height: 50px;
      padding: 12px 16px;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      color: #fff;
      background-color: #131518;
      background-clip: padding-box;
      border: 1px solid #212529;
      outline: none;
      border-radius: 12px;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
    textarea {
      height: unset !important;
    }
    .form_fields_item__list {
      .form_fields_item__list_item {
        display: flex;
        flex-direction: row;
        align-items: center;
        .form_fields_item__list_item__input {
          width: 100%;
        }
        .form_fields_item__list_item__actions {
          display: flex;
          flex-direction: row;
          align-items: center;
          div {
            min-width: 42px;
            width: 42px;
            text-align: center;
            font-size: 16px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.flexed_fields {
  display: flex;
  flex-direction: row;
  align-items: center;
  .form_fields_item {
    width: calc(100% - 8px);
    margin-right: 8px;
    &:last-child {
      margin-right: 0px !important;
      width: 100% !important;
    }
  }
}

.stats_counts {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  .stats_counts__item {
    width: calc(100% - 10px);
    margin-right: 10px;
    padding: 6px 10px;
    border-radius: 10px;
    background-color: #00000036;
    &:last-child {
      margin-right: 0px !important;
      width: 100% !important;
    }
    div {
      color: #fff;
      &:nth-child(1) {
        font-size: 10px;
        font-weight: 500;
        text-transform: uppercase;
      }
      &:nth-child(2) {
        font-size: 20px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}

.raffle_winners {
  .raffle_winners__title {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
  }
  .raffle_winners__users {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    .raffle_winners__users_item {
      width: calc(50% - 10px);
      margin-right: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      &:nth-child(2n) {
        width: 50%;
        margin-right: 0px;
      }
      .raffle_winners__users_item__avatar {
        width: 32px;
        height: 32px;
        min-width: 32px;
        border-radius: 100px;
        margin-right: 10px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      .raffle_winners__users_item__data {
        div {
          color: #fff;
          &:nth-child(1) {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: -2px;
          }
          &:nth-child(2) {
            font-size: 12px;
          }
          &:nth-child(3) {
            font-size: 8px;
          }
        }
      }
    }
  }
}

.scrollable {
  height: 300px;
  overflow: scroll;
  margin-bottom: 16px;
  position: relative;
}