.form_fields {
  .form_fields_item {
    margin-bottom: 16px;
    input {
      display: block;
      width: 100%;
      height: 50px;
      padding: 12px 16px;
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      color: #fff;
      background-color: #131518;
      background-clip: padding-box;
      border: 1px solid #212529;
      outline: none;
      border-radius: 12px;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
  }
}

.action {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 42px auto 0;
  button {
    padding: 12px 24px;
    font-size: 14px;
    &:disabled {
      opacity: 0.5;
      &:hover {
        color: #fff !important;
        background-color: #E11E1E !important;
      }
    }
  }
  a {
    color: #fff;
    border-bottom: 1px dashed;
    width: fit-content;
    margin: 24px auto 0;
  }
}

.user_not_selected {
  font-size: 18px;
  margin-bottom: 0px;
  opacity: 0.2;
}

.account_details {
  .account_details__avatar {
    width: 100%;
    div {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 96px;
      height: 96px;
      margin: 0px auto 16px;
      border-radius: 100px;
    }
  }
  .account_details__name {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 6px;
    text-align: center;
  }
  .account_details__pubkey {
    background-color: rgba(0, 0, 0, 0.318);
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    padding: 4px 10px;
    border-radius: 6px;
    width: fit-content;
    margin: 0 auto 6px;
  }
  .account_details__telegram {
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    text-align: center;
    margin-bottom: 6px;
  }
  .account_details__collection_title {
    width: fit-content;
    margin: 24px auto 0;
    background-color: #fff;
    color: #000;
    font-weight: 800;
    font-size: 18px;
    padding: 0px 8px;
    border-radius: 4px;
  }
  .collections_filters {
    width: 400px;
    margin: 16px auto 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    .collections_filters__item {
      width: 100%;
      margin-right: 12px;
      &:last-child {
        margin-right: 0px !important;
      }
      .collections_filters__item_title {
        margin-bottom: 2px;
      }
    }
  }
  .account_details__collection {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    overflow-x: scroll;
    max-width: 100%;
    width: 100%;
    margin: 24px auto 0;
    .account_details__collection_item {
      width: 23%;
      min-width: 23%;
      margin-right: 12px;
      margin-bottom: 12px;
      cursor: pointer;
      background-color: #fff;
      border-radius: 12px;
      padding: 8px;
      height: 258px;
      &:nth-child(4n) {
        margin-right: 0px;
      }
      .account_details__collection_item__img {
        width: 100%;
        border-radius: 10px;
        img {
          width: 100%;
          border-radius: 10px;
        }
      }
      .account_details__collection_item__attrs {
        margin-top: 8px;
        .account_details__collection_item__attrs_item {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: 2px;
          div {
            font-size: 12px;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            &:nth-child(1) {
              font-weight: 700;
              color: #000;
            }
            &:nth-child(2) {
              font-weight: 500;
              color: rgba(0, 0, 0, 0.774);
              text-align: right;
            }
          }
        }
      }
    }
  }
}