.auth__form {
  width: 320px;
  margin: 64px auto 42px;
  text-align: center;
  .auth__form_image {
    img {
      width: 100px;
      border-radius: 12px;
    }
  }
  .auth__form_title {
    font-size: 24px;
    font-weight: 800;
    line-height: 1.5;
    text-transform: uppercase;
    color: #fff;
    margin-top: 24px;
    margin-bottom: 36px;
  }
  .auth__form_fields {
    .auth__form_fields_item {
      margin-bottom: 16px;
      input {
        display: block;
        width: 100%;
        height: 50px;
        padding: 12px 16px;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: #000;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ffffff;
        outline: none;
        border-radius: 12px;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
      }
    }
  }
  .auth__action {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: 42px auto 0;
    button {
      padding: 12px 24px;
      font-size: 14px;
      background-color: #fff !important;
      color: #000 !important;
      &:disabled {
        opacity: 0.5;
        &:hover {
          color: #000 !important;
        }
      }
    }
    a {
      color: #fff;
      border-bottom: 1px dashed;
      width: fit-content;
      margin: 24px auto 0;
    }
  }
}