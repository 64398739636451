.nft_edit {
  padding: 16px;
  border-radius: 12px;
  border: 2px solid #1e2027;
  background-color: #1e2025;
  margin-bottom: 24px;
  h4 {
    font-size: 18px;
    margin-bottom: 0px;
    opacity: 0.2;
  }
}

.nft_list {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  .nft_list__item {
    display: block;
    position: relative;
    width: calc(25% - 8px);
    margin-right: 8px;
    background-color: #1d2025;
    padding: 8px 12px;
    border-radius: 10px;
    margin-bottom: 8px;
    cursor: pointer;
    &:nth-child(4n) {
      width: 25%;
      margin-right: 0px;
    }
    .nft_list__item_status {}
    .nft_list__item_id {}
    .nft_list__item_title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 13px;
      font-weight: 600;
      small {
        opacity: 0.4;
      }
    }
    .nft_list__item_url {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 9px;
      opacity: 0.4;
      margin-top: 4px;
    }
    .nft_list__item_price {
      font-size: 12px;
      opacity: 0.7;
    }
  }
}

@media screen and (max-width: 960px) {
  .nft_list__item {
    width: calc(50% - 8px) !important;
    &:nth-child(4n) {
      width: calc(50% - 8px) !important;
      margin-right: 8px !important;
    }
    &:nth-child(2n) {
      width: 50% !important;
      margin-right: 0px !important;
    }
  }
}

.images_ups {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  .imups {
    width: 20%;
  }
}

@media screen and (max-width: 960px) {
  .images_ups {
    .imups {
      width: 50% !important;
    }
  }
}